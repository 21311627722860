import React from "react"
import { Link, graphql } from "gatsby" //graphqlをインポートします

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="あるふぁちっく" keywords={['gatsby','application','react']}/>
    <h1>鏡.web Vol.1</h1>
    {data.allKagamiWebYaml.edges.map(edge => {
      const work = edge.node
      if (work.link_kakuyomu == '未投稿') {
      return(
        <div>
          {work.author}『{work.title}』 - 未投稿
        <br/>
        <br/>
        </div>
      ) 
      }
      return (
        <div>
          <Link to={`${work.link_kakuyomu}`}>
            {work.author}『{work.title}』 - カクヨム
          </Link>
          <br/>
          <Link to={`${work.link_narou}`}>
            {work.author}『{work.title}』 - 小説家になろう
          </Link>
          <br/>
          {work.description}
          <br/>
          <br/>
        </div>
      )
    })}
    <div style={{ maxWidth: `1000px`, marginBottom: `1.45rem` }}>
      <Image filename="kagami_web1_header.png" alt="鏡.Web Vol.1" />
    </div>
    {
    /* <Link to="/about/">Go to about page</Link><br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
)

//queryという名前でexportするとGraplQLが実行され、コンポーネントのprops.dataとして結果が渡されます
// export const query = graphql`
//   query{
//     allWorksYaml {
//       edges {
//         node {
//           title
//           category
//           year
//           slug
//         }
//       }
//     }
//   }
//   `

  // const filename = '../components/images/kagami_web1_header.png'
  // return <Image filename={filename} />


export const query = graphql`
  query{
    allKagamiWebYaml {
      edges {
        node {
          title
          category
          year
          description
          link_kakuyomu
          link_narou
          author
        }
      }
    }
  }
  `

export default IndexPage
